<template>
  <v-row 
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="4"
    >
      <v-card class="elevation-12">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Register form</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="validate"
          >
            <v-text-field
              v-model="name"
              label="Name"
              :rules="nameRules"
              prepend-icon="mdi-account"
              type="text"
              required
            />

            <v-text-field
              v-model="email"
              label="Email"
              :rules="emailRules"
              prepend-icon="mdi-email"
              required
            />

            <v-text-field
              v-model="password"
              label="Password"
              :rules="passwordRules"
              :counter="64"
              prepend-icon="mdi-lock"
              type="password"
              required
            />

            <v-select
              v-model="location"
              :items="locations"
              :rules="[v => !!v || 'Location is required']"
              item-text="name"
              item-value="uuid"
              prepend-icon="mdi-city"
              label="Location"
              required
            />

            <v-select
              v-model="role"
              :items="roles"
              :rules="[v => !!v || 'Role is required']"
              prepend-icon="mdi-account-details"
              label="Role"
              required
            />

            <v-col class="text-right">
              <v-btn
                type="submit"
                color="primary"
              >
                Register
              </v-btn>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({
    location: "",
    locations: [],

    role: "",
    roles: [
      "operator",
      "admin",
      "advertiser",
      "manager",
      "device"
    ],

    name: "",
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length > 6 || 'Name is too short',
      v => v.length < 64 || 'Name is too long'
    ],

    email: "",
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'Email must be valid',
    ],

    password: "",
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length > 8 || 'Password is too short',
      v => v.length < 64 || 'Password is too long'
    ],
  }),

  mounted() {
    this.fetchLocations();
  },

  methods: {
    fetchLocations() {
      axios.get("/v1/locations").then((response) => {
        this.locations = response.data.locations;
      }).catch(() => {
        console.log("bruh");
      });
    },

    async validate() {
      if (this.$refs.form.validate()) {
        this.$users.register(
          this.name,
          this.email,
          this.password,
          this.location,
          this.role
        );
      }
    }
  }
};
</script>

<style>
</style>